<template>
  <el-dialog
      title="意见受理"
      :visible.sync="showFeedBack"
      width="35%"
      @close="resetForm('ruleForm')"
      :close-on-click-modal="false"
      custom-class="feenback"
      :append-to-body="true">
      <div class="feenback-tools-content">
        <el-form :model="ruleForm" ref="ruleForm" label-width="auto" class="demo-ruleForm">
            <el-form-item label="" prop="status">
              <el-radio-group v-model="ruleForm.status">
                <el-radio  :label="1">采纳意见并改进</el-radio>
                <el-radio  :label="2">不采纳该意见</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="" v-show="ruleForm.status==2" prop="reason">
              <el-input type="textarea" :rows="4" size='small' placeholder='请填写不采纳的原因'  v-model="ruleForm.reason" ></el-input>
            </el-form-item>
            <el-form-item class="btn">
              <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
              <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
              
            </el-form-item>
          </el-form>
      </div>
  </el-dialog>
</template>
<script>
import { manageAPI } from 'liankong-ui-api'
export default {
  data() {
      return {
        showFeedBack:false,
        ruleForm: {
          status:1,
          reason:''
        }
      }
  },
  methods: {
    show(item){
      this.ruleForm = {
          status:1,
          id:item.id,
          reason:''}
      this.showFeedBack = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          manageAPI.feedBackHandle(this.ruleForm).then(res=>{
            if(res.code==0){
              this.$message.success('受理成功')
              this.resetForm(formName)
              this.$emit('reloadList')
            } else {
              this.$message.error(res.message)
              this.resetForm(formName)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm={
          status:1,
          reason:'',
          id:''
        }
      this.showFeedBack = false
    }
  }
}
</script>
<style lang="less">

</style>
<style lang="less" scoped>
/deep/ .feenback{
.el-dialog__body {
  padding:10px 70px 20px 70px;
}
.feenback-tools-content {
  font-size: 14px;
    p {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        span {
            padding-top: 10px;
            box-sizing: border-box;
        }
    }
    .el-form-item__content {
      // margin-left:0px !important;
      .el-textarea,.el-select,.el-input{
        width: 85%;
        .el-input {
          width: 100%;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-form-item__content {
        margin-left:0px !important;
      }
    }
    .upload-list {
      ul,li{
        list-style: none;
      }
      .el-form-item__content {
        line-height: 30px;
      }
      .el-upload__tip {
        margin-top:0;
      }
      li{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        box-sizing: border-box;
        .cure {
          cursor: pointer;
        }
      }
    }
}
}

</style>