<template>
  <div style="padding: 20px;">
      <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
      <template>
          <TableCommon class="art" isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
            <el-table-column
              width="120"
              align="center"
              label="操作">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                <el-button type="text" v-show="scope.row.status==0" @click="changeState(scope.row)" size="small">受理</el-button>
              </template>
            </el-table-column>
          </TableCommon>
      </template>
      <el-pagination
      class="pagebox"
      @current-change="handleCurrentChange"
      :current-page="formInline.pageNum"
      :page-size="formInline.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
      <Detail ref="detail" />
      <Handle ref="handle" @reloadList="onLoadList"/>
  </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from './TableCommon.vue';
import Detail from './detail.vue'
import Handle from './handle.vue'
export default {
  data() {
      return {
          vkey: 'toolsFeedBack',
          formInline: {
              type: null,
              status:null,
              pageNum: 1,
              pageSize: 10
          },
          formList: [
              { label: '反馈类型', model: 'type', placeholder: '请输入工具名称', type: 'select',options: [
                  { value: null, label: '请选择'},{value:0,label:'功能需求'},{value:1,label:'系统问题'},{value:99,label:'商务合作'}] },
              { label: '是否受理', model: 'status', placeholder: '请选择', type: 'select', options: [
                  { value: null, label: '请选择'}, { value: 0, label: '未受理'}, { value: 1, label: '已受理'}, { value: 2, label: '拒绝受理'}]
              }
          ],
          tableColumn: [
              { label: '序号', type: 'index', align: 'center', width: '60' },
              { label: '反馈类型', prop: 'typeName', align: 'center',width: '100' },
              { label: '反馈内容', prop: 'content', align: 'center' },
              { label: '联系方式', prop: 'contact', align: 'center',width: '160' },
              { label: '是否受理', prop: 'statusName', align: 'center',width: '100' },
              { label: '创建时间', prop: 'feedbackTime', align: 'center',width: '180' }
          ]
      }
  },
  components: {
      TableCommon,
      SearchCommon,
      Detail,Handle
  },
  computed: {
      tableData(){
          return this.$store.state.manage.views[this.vkey].list || []
      },
      total() {
          return this.$store.state.manage.views[this.vkey].paging.total;
      }
  },
  created(){
      this.onLoadList()
  },
  methods: {
    changeState(item){
      this.$refs['handle'].show(item)
    },
    handleClick(item){
      this.$refs['detail'].show(item)
    },
      // 列表
      onLoadList(){
          this.$store.dispatch('manage/list', {
              vkey: this.vkey,
              reset: true,
              query: this.formInline,
              interfaceName: 'getFeedBackList'
          })
      },
      // 搜索
      onSearch() {
          this.formInline.pageNum = 1;
          this.onLoadList();
      },
      // 清空
      onResetBtn() {
          this.formInline = {
              name: '',
              pageNum: 1,
              pageSize: 18
          }
          this.onLoadList()
      },
      // 分页
      handleCurrentChange(val){
          this.formInline.pageNum = val;
          this.onLoadList();
      }
  }
}
</script>
<style lang="less" scoped>
.art {
 /deep/ .el-table {
      .el-table__body-wrapper {
          .el-table__body tr td {
              padding: 10px !important;
          }
      }
  } 
}
</style>