<template>
  <el-dialog
      title="意见反馈详情"
      :visible.sync="showFeedBack"
      width="40%"
      @close="resetForm('ruleForm')"
      :close-on-click-modal="false"
      custom-class="feenback"
      :append-to-body="true">
      <div class="feenback-tools-content">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <div style="display: flex;">
                <el-form-item label="反馈类型:" prop="type">
                  {{ ruleForm.typeName }}
                </el-form-item>
                <el-form-item label="是否受理:" prop="type">
                  {{ ruleForm.typeName }}
                </el-form-item>
            </div>
            <el-form-item label="反馈内容:" prop="content">
              {{ruleForm.content}}
            </el-form-item>
            <el-form-item label="附件:" class="upload-list">
                <ul>
                  <li v-for="(item,index) in ruleForm.attachList" :key="index" @click="read(item)">
                    <img v-if="item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='png' || item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='jpg' || item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='gif'" :src="item" alt="">
                    <img v-if="item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='doc' || item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='docx'" src="@/assets/img/icon-word.png" alt="">
                    <img v-if="item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='ppt' || item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='pptx'" src="@/assets/img/icon-ppt.png" alt="">
                    <img v-if="item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='xls' || item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1)=='xlsx'" src="@/assets/img/icon-excl.png" alt="">
                    <span>受理内容{{ index+1 }}</span>
                    <!-- <span class="el-icon-close cure" @click="delteFile(item,index)"></span> -->
                  </li>
                </ul>
            </el-form-item>
            <el-form-item label="联系方式:" prop="contact">
              {{ ruleForm.contact }}
            </el-form-item>
            <el-form-item label="未受理原因:" v-show="ruleForm.status==2" prop="contact">
              {{ ruleForm.reason }}
            </el-form-item>
            <el-form-item class="btn">
              <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
            </el-form-item>
          </el-form>
      </div>
  </el-dialog>
</template>
<script>
import { read } from 'fs'

export default {
  data() {
      return {
        showFeedBack:false,
        ruleForm: {},
        fileList:[],

        timer:null, // 定时器
      }
  },
  methods: {
    show(item){
      this.ruleForm = {}
      this.ruleForm = item
      this.showFeedBack = true
    },
    read(item){
      const imgType = ['png','jpg','gif']
      if(imgType.indexOf(item.toLocaleLowerCase().substring(item.lastIndexOf('.') + 1))!=-1){
        window.open(item,'_black')
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm={
          type:'',
          attachList:[],
          content:'', // 反馈内容
          contact:'' // 反馈练习方式
        }
      this.fileList=[]
      this.showFeedBack = false
    }
  }
}
</script>
<style lang="less">

</style>
<style lang="less" scoped>
/deep/ .feenback{
.el-dialog__body {
  padding:10px 70px 20px 70px;
}
.feenback-tools-content {
  font-size: 14px;
    p {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        span {
            padding-top: 10px;
            box-sizing: border-box;
        }
    }
    .el-form-item__content {
      // margin-left:0px !important;
      .el-textarea,.el-select,.el-input{
        width: 85%;
        .el-input {
          width: 100%;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-form-item__content {
        margin-left:0px !important;
      }
    }
    .upload-list {
      ul,li{
        list-style: none;
      }
      .el-form-item__content {
        line-height: 30px;
      }
      .el-upload__tip {
        margin-top:0;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li{
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 0;
        box-sizing: border-box;
        .cure {
          cursor: pointer;
        }
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
      }
      }
    }
}
}

</style>